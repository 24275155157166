// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  text-align: center;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  max-height: 90vh !important;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(720deg);
  }
}

@keyframes rotate-reverse {
  from {
    transform: rotate(720deg);
  }
  to {
    transform: rotate(0deg);
  }
}

.rotate-image {
  animation: rotate 5s ease-in-out; /* Rotate infinitely with a duration of 5 seconds */
}

.rotate-image-reverse {
  animation: rotate-reverse 5s ease-in-out; /* Rotate infinitely with a duration of 5 seconds */
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,aAAa;EACb,2BAA2B;AAC7B;;AAEA;EACE;IACE,uBAAuB;EACzB;EACA;IACE,yBAAyB;EAC3B;AACF;;AAEA;EACE;IACE,yBAAyB;EAC3B;EACA;IACE,uBAAuB;EACzB;AACF;;AAEA;EACE,gCAAgC,EAAE,mDAAmD;AACvF;;AAEA;EACE,wCAAwC,EAAE,mDAAmD;AAC/F","sourcesContent":[".App {\n  text-align: center;\n}\n\n.container {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  padding: 20px;\n  max-height: 90vh !important;\n}\n\n@keyframes rotate {\n  from {\n    transform: rotate(0deg);\n  }\n  to {\n    transform: rotate(720deg);\n  }\n}\n\n@keyframes rotate-reverse {\n  from {\n    transform: rotate(720deg);\n  }\n  to {\n    transform: rotate(0deg);\n  }\n}\n\n.rotate-image {\n  animation: rotate 5s ease-in-out; /* Rotate infinitely with a duration of 5 seconds */\n}\n\n.rotate-image-reverse {\n  animation: rotate-reverse 5s ease-in-out; /* Rotate infinitely with a duration of 5 seconds */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
